import axios from '../../utils/axios';
import { objectToFormData } from '../utils';

// GET
// /Api/Rental/Vendors
export const getVendorData = async () => {
  const response = await axios.get('Rental/Vendors');
  return response.data.map((x, y) => {
    x.id = y;
    return x;
  });
};

// GET
//Api/Rental/CompaniesAndFacilities
export const getCompaniesAndFacilities = async () => {
  const response = await axios.get('Rental/CompaniesAndFacilities');
  return response.data;
};

// GET
//Api/Rental/Residents facilityId
export const getResidents = async (facilityId) => {
  const response = await axios.get(`Rental/Residents?facilityId=${facilityId}`);
  return response;
};

// GET
//Api/Rental/Carts
export const getCarts = async () => {
  const response = await axios.get('Rental/Carts');
  return response;
};

// GET
//Api/Rental/facilityInfo facilityId
export const getFacilityInfo = async (facilityId) => {
  const response = await axios.get(`Rental/FacilityInfo?facilityId=${facilityId}`);
  return response;
};

// POST
//Api/Rental/CreateResident form
export async function postCreateResident(form, formFacilityId) {
  const updatedForm = {
    facilityId: formFacilityId,
    ...form,
  };

  const formData = objectToFormData(updatedForm);

  const response = await axios.post('Rental/CreateResident', formData);
  return response;
}

// GET
//Api/Rental/Items
export const getItems = async () => {
  const response = await axios.get('Rental/Items');
  return response;
};

// GET
//Api/Rental/VendorNames
export const getVendorNames = async () => {
  const response = await axios.get(`Rental/VendorNames`);
  return response;
};

// POST
//Api/AddToCart (when cart already exists) items array
export async function postAddToCart(itemId, cartId, userId) {
  const formData = objectToFormData({
    itemId,
    cartId,
    userId,
  });
  const response = await axios.post('Rental/AddToCart', formData);
  return response;
}

// POST
//Api/AddToCart (create new cart) items array
export async function postAddNewCart(residentId, userId) {
  const formData = objectToFormData({
    residentId,
    userId,
  });
  const response = await axios.post('Rental/AddNewCart', formData);
  return response;
}

// POST
//Api/Rental/RemoveFromCart cartItemId, deleteAll
export async function postRemoveCartItem(cartItemId, deleteAll) {
  const response = await axios.post(`Rental/RemoveCartItem?cartItemId=${cartItemId}&deleteAll=${deleteAll}`);
  return response;
}

//POST
//Api/Rental/postSubmitOrderApi items
export async function postSubmitOrderApi(items) {
  const formData = new FormData();
  const updateItems = items?.map((item) => ({
    cartItemId: item.cartItemId,
    isSelected: item.isSelected,
  }));
  updateItems.forEach((item, index) => {
    Object.entries(item).forEach(([key, value]) => {
      formData.append(`${'orderItems'}[${index}].${key}`, value !== null && value !== '' ? value : '');
    });
  });

  const response = await axios.post('Rental/SubmitOrder', formData);
  return response;
}
// GET
// /Api/Rental/RentalOrders
export const getRentalOrders = async (orderStatus) => {
  const response = await axios.get(`Rental/RentalOrders?orderStatus=${orderStatus}`);
  return response;
};

// POST
//Api/Rental/UpdateOrderStatus orderId, updatedStatus
export async function postUpdateOrderStatus(orderId, updatedStatus) {
  const response = await axios.post(`Rental/UpdateOrderStatus?orderId=${orderId}&updatedStatus=${updatedStatus}`);
  return response;
}
