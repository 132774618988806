import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Avatar, Box, Card, Chip, Divider, Grid, IconButton, Link, Menu, MenuItem, Select, Stack, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { editTicketData } from '../../../../redux/slices/tickets';
import { useDispatch, useSelector } from '../../../../redux/store';
import { CustomAvatar } from '../../../../reusable-components/custom-avatar';
import Iconify from '../../../../reusable-components/iconify';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import NewUser from './NewUser';
import TicketRecipients from './TicketRecipients';

export default function TicketInfo() {
  const location = useLocation();
  const { id = '' } = useParams();
  const subticketId = new URLSearchParams(location.search).get('subticket');

  const { subtickets, mainTicket, ticketUsers } = useSelector((state) => state.tickets.data.ticketInfoById[id]) || {};
  const { ticketNumber } = useSelector((state) => state.tickets.data.ticketById[subticketId || id]) || {};
  const { email: userEmail, roles } = useSelector((state) => state.user.user);

  const { facilities } = useSelector((state) => state.tickets.data);
  const dispatch = useDispatch();
  const [editFacility, setEditFacility] = useState(false);
  const [participants, setParticipants] = useState([]);
  const { data: { users: allUsers } } = useSelector((state) => state.tickets);
  //--menu 
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //-- end menu
  useEffect(() => {
    const recipients = [];

    ticketUsers?.forEach((user) => {
      if (!user.userName) {
        const userObj = allUsers.find((u) => u.id === user.userEmail);
        recipients.push({ id: user.userEmail, value: userObj?.value || null });
      } else {
        recipients.push({ id: user.userEmail, value: user.userName });
      }
    });

    setParticipants(recipients);

  }, [allUsers, ticketUsers]);

  const handleDelete = (newValue) => {
    editTicket({
      Id: ticketNumber || id,
      value: newValue.filter((user) => user.id).map((user) => user.id),
      updateType: 'Users',
    });
    setParticipants(newValue);
  };
  const [facility, setFacility] = useState(facilities.find((facility) => facility.id === mainTicket?.facilityId));
  const facilityDropdownOptions = facilities
    .map((f) => (
      <MenuItem key={f.id} value={f.id}>
        {f.shortName || f.facilityName}
      </MenuItem>
    ));
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setFacility(facilities.find((facility) => facility.id === mainTicket?.facilityId));
  }, [mainTicket, facilities]);

  const editTicket = async ({ Id, value, updateType }) => {
    const response = await dispatch(editTicketData({ Id: mainTicket?.ticketNumber || Id, value, updateType }));
    if (response) {
      enqueueSnackbar('Facility updated successfully', { variant: 'success' });
    } else {
      enqueueSnackbar('Facility failed to update', { variant: 'error' });
    }
    return response;
  };

  const handleChange = async (event) => {
    setEditFacility(false);
    const edited = await editTicket({ Id: ticketNumber || id, value: event.target.value, updateType: 'FacilityId' });
    if (edited) setFacility(facilities.find((f) => f.id === event.target.value));
  };

  const handleCancel = () => {
    setEditFacility(false);
  };


  return (
    <Card sx={{ height: '100%', mb: 2, p: 3 }}>
      <Grid container direction="column" justifyContent="center" alignItems="flex-start">
        <Grid item>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                {mainTicket?.facility && (
                  <Grid item sx={{ pb: 2 }}>
                    {editFacility ? (
                      <>
                        <Select
                          value={facility?.id}
                          onChange={handleChange}
                          displayEmpty
                          sx={{
                            textAlign: 'left',
                            minWidth: 150,
                            borderRadius: '5px',
                            maxHeight: '30px',
                            border: `solid 1px info.main`,
                            marginLeft: '-15px',
                            fontSize: '15px',
                            color: (theme) => theme.palette.info.main,
                            '& .MuiSelect-icon': {
                              color: (theme) => theme.palette.info.main,
                            },
                          }}
                        >
                          {facilityDropdownOptions}
                        </Select>
                        <IconButton onClick={() => handleCancel()} sx={{ ml: 0 }} color="primary">
                          <Iconify icon="material-symbols:cancel" width={15} height={5} sx={{ mr: 0.5 }} />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <Grid container direction="row" justifyContent="flex-start">
                          <Grid item sx={{ mr: 3 }}>
                            {facility?.logo?.length > 0 ? (
                              <Box
                                component="img"
                                src={facility?.logo}
                                sx={{
                                  color: 'white.main',
                                  height: '35px',
                                  objectFit: 'contain',
                                  borderRadius: `2px`,
                                }}
                              />
                            ) : <Avatar sx={{ p: 1, bgcolor: (theme) => theme.palette.info.main }}>
                              <Iconify icon="mdi:building" width={30} height={30} />
                            </Avatar>
                            }
                          </Grid>
                          <Grid item >
                            <Typography variant="h5" color="info.main">
                              {facility?.shortName || facility?.facilityName}
                              <ScopeGuard scopes={['Tickets-Edit']} allowAdmin>
                                <IconButton onClick={() => setEditFacility(true)} sx={{ ml: 0 }} color="primary">
                                  <Iconify icon="material-symbols:edit" width={15} height={5} sx={{ mr: 0.5 }} />
                                </IconButton>
                              </ScopeGuard>
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                )}
                {facility?.company && (
                  <Grid item>
                    <Tooltip title="Group">
                      <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Typography variant="subtitle2" display="inline">
                          <Iconify
                            icon="mdi:company"
                            width={16}
                            height={16}
                            color="secondary"
                            sx={{ mr: 0.5 }}
                          />
                        </Typography>
                        <Typography variant="subtitle2" display="inline">
                          {facility?.company}
                        </Typography>
                      </Grid>
                    </Tooltip>
                  </Grid>
                )}
                {facility?.address && (
                  <Grid item>
                    <Tooltip title="Facility address">
                      <Grid container direction="row" justifyContent="left" alignItems="top">
                        <Grid item xs={1} sx={{ mt: 0.5 }}>
                          <Typography variant="subtitle2" display="inline">
                            <Iconify
                              icon="mdi:house-outline"
                              width={16}
                              height={16}
                              color="secondary"
                              sx={{ mr: 0.5 }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={11}>
                          <Typography variant="subtitle2" display="inline">
                            <Link target="_blank" rel="noopener" display="inline"
                              to={`https://www.google.com/maps/place/${facility?.address}`}
                              component={RouterLink}
                              sx={{ textDecoration: 'underline' }}
                            >
                              {facility?.address}
                            </Link>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Tooltip>
                  </Grid>
                )}
                {facility?.phoneNumber && (
                  <Grid item>
                    <Tooltip title="Facility phone number">
                      <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Typography variant="subtitle2" display="inline">
                          <Iconify
                            icon="ic:baseline-local-phone"
                            width={16}
                            height={16}
                            color="secondary"
                            sx={{ mr: 0.5 }}
                          />
                        </Typography>
                        <Typography variant="subtitle2" display="inline">
                          {facility?.phoneNumber}
                        </Typography>
                      </Grid>
                    </Tooltip>
                  </Grid>
                )}
                {facility?.nhaEmail && (
                  <Grid item>
                    <Tooltip title="NHA Email">
                      <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Typography variant="subtitle2" display="inline">
                          <Iconify icon="ic:outline-email" width={16} height={16} color="secondary" sx={{ mr: 0.5 }} />
                        </Typography>
                        <Typography variant="subtitle2" display="inline">
                          {facility?.nhaEmail}
                        </Typography>
                      </Grid>
                    </Tooltip>
                  </Grid>
                )}
                {facility?.beds > -1 && (
                  <Grid item>
                    <Tooltip title="Facility beds">
                      <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Typography variant="subtitle2" display="inline">
                          <Iconify icon="mdi:bed-outline" width={16} height={16} color="secondary" sx={{ mr: 0.5 }} />
                        </Typography>
                        <Typography variant="subtitle2" display="inline">
                          {facility?.beds} beds
                        </Typography>
                      </Grid>
                    </Tooltip>
                  </Grid>
                )}
                {facility?.censusNumber > -1 && (
                  <Grid item>
                    <Tooltip title="Facility census">
                      <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Typography variant="subtitle2" display="inline">
                          <Iconify icon="mdi:person-outline" width={16} height={16} color="secondary" sx={{ mr: 0.5 }} />
                        </Typography>
                        <Typography variant="subtitle2" display="inline">
                          Census {facility?.censusNumber}
                        </Typography>
                      </Grid>
                    </Tooltip>
                  </Grid>
                )}
                {facility?.facilityContacts && (
                  <Grid item>

                    <Grid container direction="row" justifyContent="center" alignItems="center">
                      <Iconify icon="mdi:contact-outline" width={16} height={16} color="secondary" sx={{ mr: 0.5 }} />
                      <Tooltip title="Facility contacts">
                        <Typography variant="subtitle2" sx={{ textDecoration: 'underline', cursor: 'pointer' }} display="inline" onClick={handleClick}>
                          {facility?.facilityContacts.length}  Facility Contacts
                        </Typography>
                      </Tooltip>
                      <Box onClick={handleClick} sx={{ cursor: 'pointer' }}>
                        {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                      </Box>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{ 'aria-labelledby': 'basic-button', }}
                        slotProps={{
                          paper: {
                            style: {
                              boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.5)'
                            },
                          },
                        }}
                      >
                        {facility?.facilityContacts?.map((contact, index) => (
                          <Grid key={index} sx={{ px: 2, pb: 1 }} container direction="column" justifyContent="center" alignItems="flex-start">
                            <Grid item>
                              <Typography variant="subtitle2">{contact?.name.length > 1 && `${contact?.name} - `} {contact?.position}
                                <Tooltip title="Add to ticket" placement="top" arrow>
                                  <IconButton onClick={() =>
                                    editTicket({ Id: id, value: [...ticketUsers.map((user) => user.userEmail), contact?.email], updateType: 'Users' })
                                  } sx={{ padding: 0 }} color="secondary">
                                    <Iconify icon="material-symbols:add" width={14} height={14} sx={{ ml: 1 }} />
                                  </IconButton>
                                </Tooltip>
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography sx={{ fontWeight: 300 }} variant="subtitle2">{contact?.email}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </Menu>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Divider color="#000" sx={{ my: 1, mx: 3 }} />
      <Stack direction="row" justifyContent="space-between" sx={{ maxWidth: '100%' }}>
        <Typography color="info.main" variant="h6">Users:</Typography>
      </Stack>
      <Typography variant="subtitle2">Main Ticket {id}:</Typography>
      <Grid container direction="column" justifyContent="center" alignItems="flex-start" spacing={4}>
        <Grid item sx={{ mt: 2 }}>
          <Box>
            <Grid container>
              {participants?.length > 0 && (
                <>
                  {participants?.map((participant, index) => (
                    <Box key={participant.id}>
                      <Chip
                        key={participant.id}
                        label={participant.value || participant?.id}
                        onDelete={() => handleDelete(participants.filter((user) => user.id !== participant.id))}
                        avatar={
                          <CustomAvatar
                            name={participant.value || participant?.id}
                            email={participant.value && participant?.id}
                            index={index}
                            key={index}
                            sx={{ '&.MuiChip-avatar': { color: '#fff', } }}
                          />
                        }
                        sx={{ mr: 1, mb: 1, maxWidth: "180px" }}
                      />
                    </Box>
                  ))}
                </>
              )}
              <Box sx={{ mt: 0.5 }}>
                <NewUser participants={participants} setParticipants={setParticipants} ticketId={id} parentTicketId={id} />
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      {subtickets?.map(sub =>
        <>
          {(sub.users.some(user => user?.userEmail?.toLowerCase() === userEmail?.toLowerCase()) || roles?.some(r => r.includes("Admin")))
            &&
            <Box key={sub.id} sx={{ mt: 1 }}>
              <Typography variant="subtitle2">Subticket {sub.ticketName}:</Typography>
              <TicketRecipients subticketId={sub.id} />
            </Box>
          }
        </>
      )}
    </Card >
  );
}
