import { createSlice } from '@reduxjs/toolkit';
import {
  getItems,
  postCreateResident,
  getResidents,
  getVendorNames,
  postAddToCart,
  postAddNewCart,
  getCarts,
  postRemoveCartItem,
  postSubmitOrderApi,
  getRentalOrders,
  postUpdateOrderStatus,
} from '../../api/rentals';

const mockData = [
  {
    id: 681,
    description: 'Air Fluidized Therapy Bed System 400 lbs.',
    price: 75.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/airus-135x148.jpg',
  },
  {
    id: 668,
    description: 'Relief Aire APM System with LAL 450 lbs. 1Y',
    price: 12.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/reliefAirLowMd.jpg?time=1707215992',
  },
  {
    id: 19,
    description: 'Relief Aire APM System with LAL - 36" x 84" 450 lbs. 1Y',
    price: 15.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/reliefAirLowMd.jpg?time=1707215992',
  },
  {
    id: 348,
    description: 'Relief Aire APM System with LAL - 42" x 80" 450 lbs. 1Y',
    price: 18.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/reliefAirLowMd.jpg?time=1707215992',
  },
  {
    id: 669,
    description: 'Relief Aire Low Air Loss System 300 lbs. 1Y',
    price: 20.0,
    image:
      'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/ReliefAireAlternating13.jpg?time=1707215992',
  },
  {
    id: 145,
    description: 'Relief Aire Low Air Loss System - 36" x 84" 300 lbs. 1Y',
    price: 20.0,
    image:
      'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/ReliefAireAlternating13.jpg?time=1707215992',
  },
  {
    id: 61,
    description: 'Relief Aire Low Air Loss System - 42" x 80" 1000 lbs. 1Y',
    price: 24.0,
    image:
      'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/ReliefAireAlternating13.jpg?time=1707215992',
  },
  {
    id: 62,
    description: 'Relief Aire Low Air Loss System - 48" x 80" 1000 lbs. 1Y',
    price: 28.0,
    image:
      'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/ReliefAireAlternating13.jpg?time=1707215992',
  },
  {
    id: 353,
    description: 'Relief Aire Low Air Loss System - 54" x 80" 1000 lbs. 1Y',
    price: 36.0,
    image:
      'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/ReliefAireAlternating13.jpg?time=1707215992',
  },
  {
    id: 717,
    description: 'Relief Turn Lateral Rotation System 650 lbs. 1Y',
    price: 25.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/reliefmaxMd.jpg?time=1707215992',
  },
  {
    id: 718,
    description: 'Relief Turn Lateral Rotation System - 42" x 80" 800 lbs. 1Y',
    price: 30.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/reliefmaxMd.jpg?time=1707215992',
  },
  {
    id: 719,
    description: 'Relief Turn Lateral Rotation System - 48" x 80" 1000 lbs. 1Y',
    price: 35.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/reliefmaxMd.jpg?time=1707215992',
  },
  {
    id: 667,
    description: 'Signa Relief APM System with LAL 325 lbs. 1Y',
    price: 8.0,
    image: '',
  },
  {
    id: 11,
    description: 'Signa Relief APM System with LAL - 36" x 84" 325 lbs. 1Y',
    price: 10.0,
    image: '',
  },
  {
    id: 877,
    description: 'Oversized Foam Mattress - 42" - 600 lb 600 lbs. 1Y',
    price: 5.0,
    image: '',
  },
  {
    id: 192,
    description: 'Oversized Foam Mattress - 48" x 84" 650 lbs. 1Y',
    price: 5.0,
    image: '',
  },
  {
    id: 454,
    description: 'Oversized Foam Mattress - 54" X 80" 1000 lbs. 1Y',
    price: 5.0,
    image: '',
  },
  {
    id: 678,
    description: 'Reduce Max Preventative Mattress 400 lbs. 5Y',
    price: 5.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/reducemaxMd.jpg?time=1707215992',
  },
  {
    id: 680,
    description: 'Reduce Max Preventative Mattress - 36" x 84" 400 lbs. 5Y',
    price: 5.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/reducemaxMd.jpg?time=1707215992',
  },
  {
    id: 679,
    description: 'Reduce Max Preventative Mattress - 42" x 80" 400 lbs. 5Y',
    price: 5.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/reducemaxMd.jpg?time=1707215992',
  },
  {
    id: 511,
    description: 'Reduce Max Preventative Mattress - 42" x 84" 400 lbs. 5Y',
    price: 5.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/reducemaxMd.jpg?time=1707215992',
  },
  {
    id: 750,
    description: 'Reduce Max Preventative Mattress - Perimeter 400 lbs. 5Y',
    price: 5.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/reducemaxMd.jpg?time=1707215992',
  },
  {
    id: 753,
    description: 'Relief Max Non-Powered Surface 500 lbs. 5Y',
    price: 8.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/10/DynoRelief.jpg?time=1707716280',
  },
  {
    id: 757,
    description: 'Relief Max Non-Powered Surface - 36" x 84" 500 lbs. 5Y',
    price: 15.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/10/DynoRelief.jpg?time=1707716280',
  },
  {
    id: 754,
    description: 'Relief Max Non-Powered Surface - 42" x 80" 500 lbs. 5Y',
    price: 20.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/10/DynoRelief.jpg?time=1707716280',
  },
  {
    id: 756,
    description: 'Relief Max Non-Powered Surface - 48" x 80" 500 lbs. 5Y',
    price: 24.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/10/DynoRelief.jpg?time=1707716280',
  },
  {
    id: 147,
    description: 'Titan Low Air Loss Integrated Bed 600 lbs.',
    price: 36.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2013/07/Titan1small.png?time=1707215992',
  },
  {
    id: 64,
    description: 'Bed Frame - 42" 600 lbs. 1Y',
    price: 20.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/highlowmd.jpg?time=1707716280',
  },
  {
    id: 265,
    description: 'Bed Frame - 42"/48" With Scale 750 lbs. 1Y',
    price: 40.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/highlowmd.jpg?time=1707716280',
  },
  {
    id: 462,
    description: 'Bed Frame - 42"/48"/54" 1000 lbs. 1Y',
    price: 50.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/highlowmd.jpg?time=1707716280',
  },
  {
    id: 469,
    description: 'Bed Frame - 42"/48"/54" With Scale 1000 lbs. 1Y',
    price: 65.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/highlowmd.jpg?time=1707716280',
  },
  {
    id: 480,
    description: 'Bed Frame - 48" x 84" 650 lbs. 1Y',
    price: 32.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/highlowmd.jpg?time=1707716280',
  },
  {
    id: 439,
    description: 'Bed Frame - Matrix 450 lbs. 2Y',
    price: 8.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/highlowmd.jpg?time=1707716280',
  },
  {
    id: 440,
    description: 'Bed Frame - Matrix - 36" x 84" 450 lbs. 2Y',
    price: 8.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/highlowmd.jpg?time=1707716280',
  },
  {
    id: 923,
    description: 'Bed Frame - Matrix - 42" x 80" 450 lbs. 2Y',
    price: 20.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/highlowmd.jpg?time=1707716280',
  },
  {
    id: 442,
    description: 'Bed Frame - Matrix - 42" X 84" 450 lbs. 2Y',
    price: 20.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/highlowmd.jpg?time=1707716280',
  },
  {
    id: 746,
    description: 'Floor Bed 330 lbs. 1Y',
    price: 12.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2013/07/Titan1small-148x148.png',
  },
  {
    id: 806,
    description: 'Floor Bed - 36" X 84" 330 lbs. 1Y',
    price: 12.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2013/07/Titan1small-148x148.png',
  },
  {
    id: 772,
    description: 'Floor Bed - 42" 495 lbs. 1Y',
    price: 25.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2013/07/Titan1small-148x148.png',
  },
  {
    id: 842,
    description: 'Floor Bed - 42" X 84" 495 lbs. 1Y',
    price: 25.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2013/07/Titan1small-148x148.png',
  },
  {
    id: 747,
    description: 'Floor Bed Safety Mat 500 lbs. 1Y',
    price: 3.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2013/07/Titan1small-148x148.png',
  },
  {
    id: 97,
    description: 'Trapeze Bar - 42"/48"/54" Scale Bed - Overhead 750 lbs. 1Y',
    price: 10.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2013/05/trapezemd.jpg?time=1707215992',
  },
  {
    id: 713,
    description: 'Safety Enclosure System 450 lbs.',
    price: 30.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2013/05/Enclosure-Bed1.jpg?time=1707215992',
  },
  {
    id: 393,
    description: 'Commode - 24" Drop Arm 600/1000 lb',
    price: '',
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2013/05/commodemd.jpg?time=1707215992',
  },
  {
    id: 137,
    description: 'Overbed Table 1Y',
    price: 1.0,
    image: '',
  },
  {
    id: 114,
    description: 'Perimeter Cover 1Y',
    price: 1.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2013/05/Perimeter-CoverCrop2.jpg?time=1707215992',
  },
  {
    id: 143,
    description: 'Perimeter Cover - 42" 1Y',
    price: 1.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2013/05/Perimeter-CoverCrop2.jpg?time=1707215992',
  },
  {
    id: 115,
    description: 'Perimeter Cover - 48" 1Y',
    price: 1.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2013/05/Perimeter-CoverCrop2.jpg?time=1707215992',
  },
  {
    id: 775,
    description: 'Perimeter Cover - 84" 1Y',
    price: 1.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2013/05/Perimeter-CoverCrop2.jpg?time=1707215992',
  },
  {
    id: 59,
    description: 'Trapeze Bar 250 lbs. 1Y',
    price: 2.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2013/05/trapezemd.jpg?time=1707716280',
  },
  {
    id: 292,
    description: 'Trapeze Bar - 1000 lb 1000 lbs. 3Y',
    price: 8.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2013/05/trapezemd.jpg?time=1707716280',
  },
  {
    id: 294,
    description: 'Trapeze Bar - 450 lb 450 lbs. 3Y',
    price: 5.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2013/05/trapezemd.jpg?time=1707716280',
  },
  {
    id: 293,
    description: 'Trapeze Bar - 600 lb 600 lbs. 3Y',
    price: 7.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2013/05/trapezemd.jpg?time=1707716280',
  },
  {
    id: 422,
    description: 'Broda Midline Chair - 18" 350 lbs. 1Y',
    price: 15.0,
    image: '',
  },
  {
    id: 817,
    description: 'Broda Midline Chair - 20" 350 lbs. 1Y',
    price: 15.0,
    image: '',
  },
  {
    id: 883,
    description: 'Broda Midline Chair - 24" 350 lbs. 1Y',
    price: 18.0,
    image: '',
  },
  {
    id: 406,
    description: 'Broda Pedal Chair - 18" 350 lbs. 1Y',
    price: 10.0,
    image: '',
  },
  {
    id: 421,
    description: 'Broda Pedal Rocker - 18" 250 lbs. 1Y',
    price: 10.0,
    image: '',
  },
  {
    id: 482,
    description: 'Broda Synthesis Chair - 20" 350 lbs. 1Y',
    price: 20.0,
    image: '',
  },
  {
    id: 851,
    description: 'Broda Transport Chair - 20" 350 lbs.',
    price: 500,
    image: '',
  },
  {
    id: 112,
    description: 'Clinical Care Recliner 250 lbs. 1Y',
    price: 5.0,
    image: '',
  },
  {
    id: 500,
    description: 'Patient Transfer Chair - 250lb 1Y',
    price: 35.0,
    image: '',
  },
  {
    id: 416,
    description: 'Power Lift Recliner 325 lbs. 1Y',
    price: 10.0,
    image: '',
  },
  {
    id: 934,
    description: 'Shower-Commode Chair - 28" 750 lbs. 1Y',
    price: 10.0,
    image: '',
  },
  {
    id: 290,
    description: 'Wheelchair - Hi-Back Recline - 18" 300 lbs. 1Y',
    price: 5.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2013/05/wheelchairmd.jpg?time=1707716280',
  },
  {
    id: 202,
    description: 'Wheelchair - Hi-Back Recline - 20" 300 lbs. 1Y',
    price: 5.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2013/05/wheelchairmd.jpg?time=1707716280',
  },
  {
    id: 749,
    description: 'Wheelchair - Hi-Back Recline - 22" 450 lbs. 1Y',
    price: 7.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2013/05/wheelchairmd.jpg?time=1707716280',
  },
  {
    id: 951,
    description: 'Wheelchair - Hi-Back Recline - 28" 700 lbs.',
    price: 18.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2013/05/wheelchairmd.jpg?time=1707716280',
  },
  {
    id: 113,
    description: 'Clinical Care Recliner - 30" 450 lbs. 1Y',
    price: 8.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2013/05/Geri-Chair-Standard-131x148.jpg',
  },
  {
    id: 123,
    description: 'Clinical Care Recliner - 40" 850 lbs. 1Y',
    price: 10.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2013/05/Geri-Chair-Standard-131x148.jpg',
  },
  {
    id: 783,
    description: 'Patient Lift - 1000 lb 1000 lbs. 1Y',
    price: 30.0,
    image: '',
  },
  {
    id: 734,
    description: 'Patient Lift - 400/500/600 lb 600 lbs. 1Y',
    price: 15.0,
    image: '',
  },
  {
    id: 784,
    description: 'Patient Lift Scale - 1000 lb 1000 lbs. 1Y',
    price: 10.0,
    image: '',
  },
  {
    id: 744,
    description: 'Patient Lift Scale - 400/500/600 lb 600 lbs. 1Y',
    price: 5.0,
    image: '',
  },
  {
    id: 517,
    description: 'Patient Transfer Chair - 400lb 1Y',
    price: 50.0,
    image: '',
  },
  {
    id: 513,
    description: 'Patient Transfer Chair - 700lb 1Y',
    price: 55.0,
    image: '',
  },
  {
    id: 261,
    description: 'Sit To Stand Lift - 400 lb 400 lbs. 1Y',
    price: 15.0,
    image: '',
  },
  {
    id: 262,
    description: 'Sit To Stand Lift - 600 lb 600 lbs. 1Y',
    price: 15.0,
    image: '',
  },
  {
    id: 291,
    description: 'Wheelchair - 22" 500 lbs. 1Y',
    price: 5.0,
    image: '',
  },
  {
    id: 104,
    description: 'Wheelchair - 24" 500 lbs. 1Y',
    price: 5.0,
    image: '',
  },
  {
    id: 150,
    description: 'Wheelchair - 26" 650 lbs. 1Y',
    price: 8.0,
    image: '',
  },
  {
    id: 388,
    description: 'Wheelchair - 28" 700 lbs. 1Y',
    price: 10.0,
    image: '',
  },
  {
    id: 105,
    description: 'Wheelchair - 30" 650 lbs. 1Y',
    price: 10.0,
    image: '',
  },
  {
    id: 515,
    description: 'Wheelchair - 34" 850 lbs. 1Y',
    price: 20.0,
    image: '',
  },
  {
    id: 122,
    description: 'CPM Machine 1Y',
    price: 20.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/CPM-Machine-148x112.jpg',
  },
  {
    id: 212,
    description: 'DVT - Pneumatic Compression Therapy System 1Y',
    price: 10.0,
    image: '',
  },
  {
    id: 791,
    description: 'Lymphedema Pump 1Y',
    price: 10.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2012/11/Lymphedema-Pump-New.jpg?time=1707215992',
  },
  {
    id: 351,
    description: 'SmartVest Airway Clearance System',
    price: 20.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2016/05/SmartVest-Vest-228x215.jpg',
  },
  {
    id: 676,
    description: 'Relief Chair Alternating Cushion System 250 lbs. 1Y',
    price: 5.0,
    image: 'https://g7ucd0.p3cdn1.secureserver.net/wp-content/uploads/2016/03/Relief-Chair-pump-406x215.jpg',
  },
];

const initialState = {
  isLoading: false,
  error: null,
  data: {
    cart: [],
    items: [],
    rentalVendors: [],
    badge: 0,
    categoryData: {},
    rentalData: mockData,
    residentsList: [],
    pendingRentals: [],
  },
};

const slice = createSlice({
  name: 'rentals',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    addToCartSuccess(state, action) {
      state.isLoading = false;
      state.data.badge = 1;
      state.data.cart = action.payload;
      state.data.cart.map((rentalItem) => {
        rentalItem.isSelected = true;
        return rentalItem;
      });
    },
    getCartSuccess(state, action) {
      state.isLoading = false;
      state.data.cart = action.payload;
    },

    removeFromReduxCart(state, action) {
      const { cart } = state.data;
      const cartItemId = action.payload;
      const index = cart?.findIndex((rentalItem) => cartItemId === rentalItem.cartItemId);
      if (cart[index].quantity === 1) {
        cart?.splice(index, 1);
      } else cart[index].quantity--;
    },

    clearBadge(state, action) {
      state.data.badge = 0;
    },

    selectedItem(state, action) {
      const { cart } = state.data;
      const { cartItem, isSelected } = action.payload;
      const index = cart?.findIndex(
        (rentalItem) => cartItem.itemId === rentalItem.itemId && cartItem.residentId === rentalItem.residentId
      );
      cart[index].isSelected = !cart[index].isSelected;
    },

    deselectResidentCartItems(state, action) {
      const { cart } = state.data;
      const residentId = action.payload;
      cart.map((rentalItem) => {
        if (residentId === rentalItem.residentId) {
          rentalItem.isSelected = false;
        }
        return rentalItem;
      });
    },
    getItemsSuccess(state, action) {
      state.isLoading = false;
      state.data.items = action.payload;
    },
    getVendorNamesSuccess(state, action) {
      state.isLoading = false;
      state.data.rentalVendors = action.payload;
    },
    getResidentsSuccess(state, action) {
      state.isLoading = false;
      state.data.residentsList = action.payload;
    },
    postCreateResidentSuccess(state, action) {
      state.isLoading = false;
      state.data.residentsList = [...state.data.residentsList, action.payload];
    },
    postSubmitOrderSuccess(state, action) {
      state.isLoading = false;
    },
    getPendingRentalsSuccess(state, action) {
      state.isLoading = false;
      state.data.pendingRentals = action.payload;
    },
    postUpdateOrderStatusSuccess(state, action) {
      state.isLoading = false;
    },
  },
});

export function addItemToCart(itemId, residentId, userId, cartId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (cartId === 0) {
        const response = await postAddNewCart(residentId, userId);
        const cartId = response.data.id;
        const resp = await postAddToCart(itemId, cartId, userId);
        if (resp.status === 200) {
          dispatch(slice.actions.addToCartSuccess(resp.data));
        }
      } else {
        const resp = await postAddToCart(itemId, cartId, userId);
        if (resp.status === 200) {
          dispatch(slice.actions.addToCartSuccess(resp.data));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCartsForUser() {
  return async (dispatch) => {
    try {
      const response = await getCarts();
      dispatch(slice.actions.getCartSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function removeItemFromCart(cartItemId, deleteAll) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await postRemoveCartItem(cartItemId, deleteAll);

      if (response.status === 200) {
        dispatch(slice.actions.removeFromReduxCart(cartItemId));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearBadgeFromRedux() {
  return async (dispatch) => {
    dispatch(slice.actions.clearBadge());
  };
}

export function updateSelectedItem(cartItem, isSelected) {
  return async (dispatch) => {
    dispatch(slice.actions.selectedItem({ cartItem, isSelected }));
  };
}

export function deselectAllItems(residentId) {
  return async (dispatch) => {
    dispatch(slice.actions.deselectResidentCartItems(Number(residentId)));
  };
}
export function getRentalItems() {
  return async (dispatch) => {
    try {
      const response = await getItems();
      dispatch(slice.actions.getItemsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getRentalVendors() {
  return async (dispatch) => {
    try {
      const response = await getVendorNames();
      dispatch(slice.actions.getVendorNamesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getResidentsForFacility(facilityId) {
  return async (dispatch) => {
    try {
      const response = await getResidents(facilityId);
      dispatch(slice.actions.getResidentsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function postAddResident(resident, facilityId) {
  return async (dispatch) => {
    try {
      const response = await postCreateResident(resident, facilityId);

      dispatch(slice.actions.postCreateResidentSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    return false;
  };
}
export function postSubmitOrder(items) {
  return async (dispatch) => {
    try {
      const response = await postSubmitOrderApi(items);
      dispatch(slice.actions.postSubmitOrderSuccess(items));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}
export function getRentalOrdersRedux(orderStatus) {
  return async (dispatch) => {
    try {
      const response = await getRentalOrders(orderStatus);
      dispatch(slice.actions.getPendingRentalsSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}
export function updateOrderStatus(orderId, updatedStatus) {
  return async (dispatch) => {
    try {
      const response = await postUpdateOrderStatus(orderId, updatedStatus);
      dispatch(slice.actions.postUpdateOrderStatusSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export default slice.reducer;
