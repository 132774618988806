import axios from '../../utils/axios';
import { objectToFormData } from '../utils';

// /ActiveTickets done
// /CompleteTickets done
// /TicketData done
// /MarkTicketAsNeedsResponse done
// /PinTicket done
// /TicketUsers done
// /TicketMessages done
// /SetTicketMessagesRead done
// /AddTicket done
// /SendTicketMessage done
// /AddTicketImage done
// /EditTicket done
// /TicketPageData done
// /ToggleTicketsComplete done
// /TicketFile done

// GET
// /TicketStatuses

// GET

export async function getActiveTicketsData() {
  const response = await axios.get('/ActiveTickets');
  return response;
}
export async function getCompletedTicketsData({ startDate, endDate }) {
  const response = await axios.get(`/CompleteTickets?Start=${startDate}&End=${endDate}`);
  return response;
}
//get
//TicketUsers id
export async function getTicketDataData({ id }) {
  const response = await axios.get(`/TicketData?id=${id}`);
  return response;
}
export async function getTicketUsersData({ id }) {
  const response = await axios.get(`/TicketUsers?id=${id}`);
  return response;
}
//TicketMessages 2 params id and type, type is optional can only be order
export async function getTicketMessagesData(id) {
  const response = await axios.get(`/TicketMessages?id=${id}`);
  return response;
}
export async function getTicketMessagesFromOrdersData(id) {
  const response = await axios.get(`/TicketMessages?id=${id}&type=order`);
  return response;
}

export async function getTicketPageData() {
  const response = await axios.get('/TicketPageData');
  return response;
}
//////////////////////////////////////////////////////////////////////////
//post
export async function postMarkTicketAsNeedsResponse({ ticketId }) {
  const response = await axios.post(`/MarkTicketAsNeedsResponse?ticketId=${ticketId}`);
  return response;
}
//PinTicket ticketId
export async function postPinTicket({ ticketId }) {
  const response = await axios.post(`/PinTicket?ticketId=${ticketId}`);
  return response;
}

//DeleteTicketMessage ticketMessageId
export async function postDeleteTicketMessage(ticketMessageId) {
  const response = await axios.post(`/DeleteTicketMessage?ticketMessageId=${ticketMessageId}`);
  return response;
}

//SetTicketMessagesRead id markAsUnread default false
export async function postSetTicketMessagesRead({ id, markAsUnread }) {
  const response = await axios.post(`/SetTicketMessagesRead?id=${id}&markAsUnread=${markAsUnread}`);
  return response;
}

export async function postSendTicketMessage({ message, ticketNumber, notify = false, files, users }) {
  const formData = new FormData();
  if (!files && !message) return console.log('no files or message');

  if (files && !message) formData.append('message', files.map ? files.map((file) => file.name).join(', ') : files.name);
  if (message && message !== '' && message !== '<p><br></p>') formData.append('message', message);
  formData.append('ticketNumber', ticketNumber);
  formData.append('notify', notify);
  if (files)
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
  if (users) {
    for (let i = 0; i < users.length; i++) {
      formData.append('addUsers', users[i]);
    }
  }
  const response = await axios.post(`/SendTicketMessage`, formData);
  return response;
}

export async function postAddTicket({
  // Id,
  ParentTicketId,
  Importance = 'Medium',
  // Type,
  ExtraInfo,
  FacilityId,
  Users,
  message,
  ticketOrderId,
  files,
  notify,
}) {
  const formData = new FormData();
  // formData.append('Id', Id);
  formData.append('Importance', Importance);
  // formData.append('Type', Type);
  formData.append('ExtraInfo', ExtraInfo);
  formData.append('TicketOrderId', ticketOrderId);
  formData.append('Notify', notify);
  if (FacilityId) {
    formData.append('FacilityId', FacilityId);
  }

  if (ParentTicketId) {
    formData.append('ParentTicketId', ParentTicketId);
  }

  if (files) {
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
  }

  if (Users) {
    for (let i = 0; i < Users.length; i++) {
      formData.append(`Users[${i}].userEmail`, Users[i]);
    }
  }

  if (message) {
    formData.append('message', message);
  }

  const response = await axios.post('/AddTicket', formData);
  return response;
}

// export async function postEditTicket({ Id, FacilityId , Users, Completed,  Importance, Type ,ExtraInfo, updateType }) {
export async function postEditTicketData({ Id, value, updateType }) {
  if (updateType === 'Users') {
    const formData = objectToFormData({ Id, updateType });

    if (value) {
      for (let i = 0; i < value.length; i++) {
        formData.append(`Users[${i}].UserEmail`, value[i]);
      }

      const response = await axios.post('/EditTicket', formData);
      return response;
    }
  }

  const response = await axios.post(`/EditTicket?Id=${Id}&${updateType}=${value}&updateType=${updateType}`);
  return response;
}

// AddTicketImage picture
export async function postAddTicketImage(picture) {
  const formData = new FormData();
  formData.append('picture', picture);
  const response = await axios.post(`/AddTicketImage`, formData);
  return response;
}
export async function postToggleTicketsComplete({ ticketIds, isComplete }) {
  const response = await axios.post(
    `/ToggleTicketsComplete?${ticketIds.map((ticketId) => `tickets=${ticketId}`).join('&')}&isComplete=${isComplete}`
  );
  return response;
}

export async function advancedSearchTickets({ messageSearch, userSearch, baseTicketSearch, allResults }) {
  const newObj = {};
  if (messageSearch) newObj.messageSearch = messageSearch;
  if (userSearch) newObj.userSearch = userSearch;
  if (baseTicketSearch) newObj.baseTicketSearch = baseTicketSearch;
  newObj.all = allResults;

  const formData = objectToFormData(newObj);

  const response = await axios.post(`/TicketsAdvancedSearch`, formData);
  return response;
}

export async function postTicketBoomerang({ date, ticketId }) {
  const boomerangInSeconds = date ? Math.floor(Math.abs((new Date().getTime() - date.getTime())) / 1000)  : null;
  const response = await axios.post(`/TicketBoomerang`, objectToFormData({ boomerangInSeconds, ticketId }));
  return response;
}