import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button, DialogTitle, DialogActions, DialogContent, IconButton, Tooltip } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PageviewIcon from '@mui/icons-material/Pageview';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import LoopIcon from '@mui/icons-material/Loop';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated'; //
import AwaitButton from '../await-button/AwaitButton';

ConfirmDialog.propTypes = {
  title: PropTypes.node,
  action: PropTypes.func,
  cancelAction: PropTypes.func,
  content: PropTypes.node,
  icon: PropTypes.string,
  color: PropTypes.string,
  actionButton: PropTypes.string,
  tooltip: PropTypes.string,
  button: PropTypes.string,
  disabled: PropTypes.bool,
};

export default function ConfirmDialog({
  button,
  title = "Delete",
  content,
  action,
  cancelAction = () => null,
  icon = "delete-outline",
  color = "error",
  tooltip,
  actionButton = "Delete",
  disabled = false,
  ...other
}) {
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleOpenConfirm = (e) => {
    e.preventDefault();
    e.confirmDialog = true;
    setOpenConfirm(true);
    e.stopPropagation();
  };

  const handleCloseConfirm = () => {
    cancelAction();
    setOpenConfirm(false);
  };
  const handleAction = () => {
    action();
    setOpenConfirm(false);
  };

  const getIcon = useMemo(() => {
    if (icon === 'delete-outline') {
      return <DeleteOutlineIcon />;
    }
    if (icon === 'refresh') {
      return <LoopIcon />;
    }
    if (icon === 'email-search') {
      return <PageviewIcon />;
    }
    if (icon === 'browser-updated') {
      return <BrowserUpdatedIcon />;
    }
    if (icon === 'delete-sweep') {
      return <DeleteSweepOutlinedIcon />;
    }
    return null;
  }, [icon]);

  return (
    <>
      {button ? (
        <Button
          variant={button}
          size="small"
          color={color}
          // startIcon={<Iconify icon={icon} />}
          startIcon={getIcon}
          onClick={handleOpenConfirm}
          disabled={disabled}
          {...other}
        >
          {actionButton}
        </Button>
      ) : (
        <Tooltip title={tooltip}>
          <IconButton
            color={color}
            onClick={handleOpenConfirm}
            id="action-button"
            className="action-button"
            disabled={disabled}
          >
            {getIcon}
          </IconButton>
        </Tooltip>
      )}
      <Dialog fullWidth maxWidth="xs" open={openConfirm} onClose={handleCloseConfirm} {...other}>
        <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

        {content && <DialogContent sx={{ typography: 'body2' }}> {content} </DialogContent>}

        <DialogActions>
          <AwaitButton variant="contained" color={color} onClick={handleAction}>
            {actionButton}
          </AwaitButton>

          <Button variant="outlined" color="inherit" onClick={handleCloseConfirm}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
