import { useCallback, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useSelector } from '../../../redux/store';
import { COMPLETED_TICKETS } from '../../../reusable-components/datagrid/ticketColumns';
import CustomToolbar from './CustomToolbar';
import CustomDataGrid from '../../../reusable-components/datagrid/CustomDataGrid';
import ManagerChipsWrapper from '../../../reusable-components/datagrid/ManagerChipsWrapper';

export default function CompletedTickets() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [showSelection, setShowSelection] = useState('');
  const [selected, setSelected] = useState([]);

  const {
    data: { completedTickets, isLoading },
  } = useSelector((state) => state.tickets);

  const customToolbar = useCallback(() => {
    const isFiltered = (filter) => filter === searchParams.get('filter');
    return (
      <CustomToolbar
        setShowSelection={setShowSelection}
        showSelection={showSelection}
        selected={selected}
        isFiltered={isFiltered}
      />
    );
  }, [searchParams, showSelection, selected]);

  const rowClick = (id) => {
    navigate(`/dashboard/tickets/${id}`);
  };

  return (
    <>
      <Grid item xs={12}>
        <>
          <CustomDataGrid
            gridId="completed-tickets"
            boxSX={{ height: 'calc(100vh - 225px)' }}
            getRowHeight={() => 'auto'}
            data={completedTickets}
            gridColumns={[
              ...COMPLETED_TICKETS,
              ManagerChipsWrapper(null, 'Tickets', {
                flex: 3,
                minWidth: 180,
              }),
            ]}
            checkboxSelection={!!showSelection}
            onRowSelectionModelChange={(newSelection) => {
              setSelected(newSelection);
            }}
            forceReRender={selected}
            onRowClick={(params, e) => {
              const selection = window.getSelection().toString();
              if (selection.length > 0) return;
              rowClick(params.row.id);
            }}
            loading={isLoading}
            CustomLeftToolbar={customToolbar}
            applyQuickFilter
            advancedFilter
            advancedSearchBase="tickets"
          />
        </>
      </Grid>
    </>
  );
}
