import { useLocation } from 'react-router-dom';
import { Grid, Typography, Badge } from '@mui/material';
import { RentalSubcategorySelect } from '../../../components/dashboard/rentals/newRental';
import CartDropdown from './CartDropdown';
import RentalCartDropdown from './RentalCartDropdown';
import RentalCategoryDropdown from '../../../components/dashboard/rentals/newRental/RentalCategoryDropdown';
import { useDispatch, useSelector } from '../../../redux/store';
import { useScopeCheck } from '../../../reusable-components/scopes';
import RentalSearch from './RentalSearch';
import { useTabs } from '../../../context/TabContext';
import { resetState } from '../../../redux/slices/orderGuides';
import { fNumber } from '../../../utils/formatNumber';

export default function Navbar() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { clearFiltersAndNavigate } = useTabs();

  const {
    data: { facilityName },
  } = useSelector((state) => state.orderSheet);
  const {
    data: { pendingInvoicing, manualInvoicing },
  } = useSelector((state) => state.invoicing);
  const paths = {
    // orders: {
    //   name: 'Orders',
    //   path: '/dashboard/orders?filter=pending',
    //   key: 'orders',
    // },
    // 'new-order': {
    //   name: 'New order',
    //   path: '/dashboard/orders/new-order',
    //   key: 'new-order',
    // },
    // tickets: {
    //   name: 'Tickets',
    //   path: '/dashboard/tickets?filter=active',
    //   key: 'tickets',
    // },
  };

  const isAdmin = useScopeCheck([], true);
  const isSuperAdmin = useScopeCheck(['Super-Admin']);
  const isItemCatalogView = useScopeCheck(['Item-Catalog-View'], true);
  const isViewOrderGuide = useScopeCheck(['View-OrderGuide'], true);
  const isViewBudgets = useScopeCheck(['View-Budgets'], true);
  const isViewCensus = useScopeCheck(['View-Census'], true);
  const isUserView = useScopeCheck(['User-View'], true);
  const isViewSentEmails = useScopeCheck(['View-SentEmails'], true);
  const isViewReviewWorkflow = useScopeCheck(['Edit-ReviewWorkflow'], true);
  const hasFacilityAccess = useScopeCheck(['Facility-Manager']);
  const canViewOtherReports = useScopeCheck(['View-OtherReports'], true);
  const newInvoiceAccess = useScopeCheck(['New-Invoice']);
  const getAdminPaths = () => {
    const adminPathsObj = {};
    if (isItemCatalogView) {
      adminPathsObj.adminCatalog = {
        name: 'Catalog',
        path: '/dashboard/admin/catalog',
        key: 'catalog',
      };
    }

    if (isViewOrderGuide) {
      adminPathsObj.adminOrderGuides = {
        name: 'Order Guides',
        path: '/dashboard/admin/order-guides',
        onClick: async () => dispatch(resetState()),
        key: 'order-guides',
      };
    }

    if (isAdmin || isViewReviewWorkflow) {
      adminPathsObj.adminFacilities = {
        name: 'Facilities',
        path: '/dashboard/admin/facilities',
        key: 'facilities',
      };
    }

    if (isViewBudgets) {
      adminPathsObj.adminBudgets = {
        name: 'Budgets',
        path: '/dashboard/admin/budgets',
        key: 'budgets',
      };
    }
    if (isAdmin) {
      adminPathsObj.adminVendors = {
        name: 'Vendors',
        path: '/dashboard/admin/vendors',
        key: 'vendors',
      };
    }

    if (isViewCensus) {
      adminPathsObj.adminCensus = {
        name: 'Census',
        path: '/dashboard/admin/census',
        key: 'census',
      };
    }

    if (isUserView) {
      adminPathsObj.adminUsers = {
        name: 'Users',
        path: '/dashboard/admin/users',
        key: 'users',
      };
    }

    if (isAdmin) {
      adminPathsObj.adminPurchasingCategories = {
        name: 'Purchasing Categories',
        path: '/dashboard/admin/purchasing-categories',
        key: 'purchasing-categories',
      };
    }
    if (isViewSentEmails) {
      adminPathsObj.adminEmails = {
        name: 'Sent Emails',
        path: `/dashboard/admin/emails?start=${new Date(
          //last 30 days
          new Date(new Date().setDate(new Date().getDate() - 29))
        ).toISOString()}&end=${new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString()}`,
        key: 'emails',
      };
    }

    return adminPathsObj;
  };

  const getSuperAdminPaths = () => {
    const superAdminPathsObj = {};

    if (isSuperAdmin) {
      superAdminPathsObj.adminItemWarehouse = {
        name: 'Item Warehouse',
        path: '/dashboard/super-admin/item-warehouse',
        key: 'item-warehouse',
      };
    }
    if (isSuperAdmin) {
      superAdminPathsObj.adminEmailRecipients = {
        name: 'Email Recipients',
        path: '/dashboard/super-admin/email-recipients',
        key: 'email-recipients',
      };
    }

    if (isSuperAdmin) {
      superAdminPathsObj.adminFacilities = {
        name: 'Facilities',
        path: '/dashboard/super-admin/facilities',
        key: 'facilities',
      };
    }

    if (isSuperAdmin) {
      superAdminPathsObj.siteRules = {
        name: 'Site Rules',
        path: '/dashboard/super-admin/site-rules',
        key: 'site-rules',
      };
    }

    if (isSuperAdmin) {
      superAdminPathsObj.adminSystemNotices = {
        name: 'System Notices',
        path: '/dashboard/super-admin/system-notices',
        key: 'system-notices',
      };
    }
    if (isSuperAdmin) {
      superAdminPathsObj.adminTasks = {
        name: 'Tasks',
        path: '/dashboard/super-admin/tasks',
        key: 'tasks',
      };
    }
    if (isSuperAdmin) {
      superAdminPathsObj.adminGroups = {
        name: 'Groups',
        path: '/dashboard/super-admin/groups',
        key: 'groups',
      };
    }

    if (isSuperAdmin) {
      superAdminPathsObj.excelUpload = {
        name: 'Excel Upload',
        path: '/dashboard/super-admin/excel',
        key: 'excel',
      };
    }
    if (isSuperAdmin) {
      superAdminPathsObj.vendorCatalog = {
        name: 'Vendor Catalog',
        path: '/dashboard/super-admin/vendor-catalog',
        key: 'vendor-catalog',
      };
    }
    if (isSuperAdmin) {
      superAdminPathsObj.adminWarehouseVendors = {
        name: 'Warehouse Vendors',
        path: '/dashboard/super-admin/warehouse-vendors',
        key: 'warehouse-vendors',
      };
    }

    return superAdminPathsObj;
  };
  const getInvoicingPaths = () => {
    const invoicingPathsObj = {};
    if (newInvoiceAccess) {
      invoicingPathsObj.new = {
        name: 'New Invoices',
        path: '/dashboard/invoicing/new',
        key: 'new',
        badgeContent: manualInvoicing.length > 0 ? fNumber(manualInvoicing.length) : null,
      };
    }

    invoicingPathsObj.pending = {
      name: 'All Pending Invoices',
      path: '/dashboard/invoicing/pending',
      key: 'pending',
      badgeContent: pendingInvoicing.length > 0 ? fNumber(pendingInvoicing.length) : null,
    };

    if (hasFacilityAccess) {
      invoicingPathsObj.pendingFilter = {
        name: 'My Pending Invoices',
        path: '/dashboard/invoicing/pendingFilter',
        key: 'pendingFilter',
        badgeContent:
          pendingInvoicing.length > 0
            ? fNumber(
                pendingInvoicing.filter((invoice) => invoice.facilityManagerAssignments.find((fma) => fma.currentUser))
                  ?.length
              )
            : null,
      };
    }

    invoicingPathsObj.previous = {
      name: 'Processed Invoices',
      path: `/dashboard/invoicing/previous?start=${new Date(
        new Date().setDate(new Date().getDate() - 29)
      ).toISOString()}&end=${new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString()}`,
      key: 'previous',
    };

    invoicingPathsObj.incomplete = {
      name: 'Incomplete Statements',
      path: '/dashboard/invoicing/incomplete',
      key: 'incomplete',
    };

    invoicingPathsObj.complete = {
      name: 'Complete Statements',
      path: '/dashboard/invoicing/complete',
      key: 'complete',
    };
    if (canViewOtherReports) {
      invoicingPathsObj.reports = {
        name: 'Reports',
        path: `/dashboard/invoicing/reports?start=${new Date(
          new Date().setDate(new Date().getDate() - 29)
        ).toISOString()}&end=${new Date().toISOString()}`,
        key: 'reports',
      };
    }
    if (isSuperAdmin) {
      invoicingPathsObj.vendors = {
        name: 'Vendors',
        path: '/dashboard/invoicing/vendors',
        key: 'vendors',
        badgeContent: 'Beta',
      };
    }
    if (isSuperAdmin) {
      invoicingPathsObj.settings = {
        name: 'Settings',
        path: '/dashboard/invoicing/settings',
        key: 'settings',
      };
    }
    return invoicingPathsObj;
  };
  const adminPaths = getAdminPaths();
  const superAdminPaths = getSuperAdminPaths();
  const invoicingPaths = getInvoicingPaths();
  const settingsPaths = {
    // bookingAccounts: {
    //   name: 'Booking accounts',
    //   path: '/dashboard/settings/booking-accounts',
    //   key: 'booking-accounts',
    // },
    adminTemplates: {
      name: 'Templates',
      path: '/dashboard/settings/templates',
      key: 'templates',
    },
  };
  const rentalsPaths = {
    pendingRentals: {
      name: 'Pending',
      path: '/dashboard/rentals/pending-rentals',
      key: 'pending-rentals',
    },
    currentRentals: {
      name: 'Current',
      path: '/dashboard/rentals/current-rentals',
      key: 'current-rentals',
    },
    completedRentals: {
      name: 'Previous',
      path: '/dashboard/rentals/previous-rentals',
      key: 'previous-rentals',
    },
    rentalCatalog: {
      name: 'Catalog',
      path: '/dashboard/rentals/rental-catalog',
      key: 'rental-catalog',
    },
    rentalVendors: {
      name: 'Vendors',
      path: '/dashboard/rentals/rental-vendors',
      key: 'rental-vendors',
    },
    rentalSettings: {
      name: 'Settings',
      path: '/dashboard/rentals/rental-settings',
      key: 'rental-settings',
    },
  };
  const pathObject = pathname.includes('invoicing')
    ? invoicingPaths
    : pathname.includes('rentals')
    ? rentalsPaths
    : pathname.includes('super-admin')
    ? superAdminPaths
    : pathname.includes('admin')
    ? adminPaths
    : pathname.includes('settings')
    ? settingsPaths
    : paths;

  const checkPath = (path) => {
    path = path.split('/').pop();

    let routeName =
      pathname.split('/').filter((item) => item !== '')[1] === 'super-admin' ||
      pathname.split('/').filter((item) => item !== '')[1] === 'admin' ||
      pathname.split('/').filter((item) => item !== '')[1] === 'settings' ||
      pathname.split('/').filter((item) => item !== '')[1] === 'invoicing' ||
      pathname.split('/').filter((item) => item !== '')[1] === 'rentals'
        ? pathname.split('/').filter((item) => item !== '')[2]
        : pathname.split('/').filter((item) => item !== '')[1];
    if (routeName === 'orders' && pathname.includes('new-order')) {
      routeName = 'new-order';
    }

    return routeName === pathObject[path].key;
  };

  const isNewOrder =
    pathname.includes('new-order') &&
    !pathname.includes('/cart') &&
    !pathname.includes('/checkout') &&
    !pathname.includes('rental');
  const isNewRental =
    pathname.includes('new-rental') &&
    !pathname.includes('/rental-cart') &&
    !pathname.includes('/rental-checkout') &&
    !pathname.includes('/rental-order-summary') &&
    !pathname.includes('new-order') &&
    !pathname.includes('/cart') &&
    !pathname.includes('/checkout');

  return (
    <Grid item xs={12} sx={{ mt: -2, mb: 2 }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ ml: 6 }}>
          {Object.keys(pathObject).map((path, index) => (
            <Badge
              key={index}
              badgeContent={pathObject[path].badgeContent}
              invisible={!pathObject[path].badgeContent}
              color="info"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              max={999}
              sx={{
                '& .MuiBadge-badge': {
                  right: 22,
                  top: -3,
                  border: '2px solid #fff',
                  padding: '0 4px',
                },
              }}
            >
              <Typography
                key={index}
                sx={{
                  mr: 2,
                  textDecoration: checkPath(path) ? 'underline' : 'none',
                  cursor: 'pointer',
                }}
                variant="subtitle2"
                color={checkPath(path) ? 'primary' : 'text.secondary'}
                onClick={() => {
                  if (pathObject[path].onClick) pathObject[path].onClick();
                  clearFiltersAndNavigate(pathObject[path].path);
                }}
              >
                {pathObject[path].name}
              </Typography>
            </Badge>
          ))}
        </Grid>
        {isNewOrder && !isNewRental ? (
          <>
            <Grid container direction="row" sx={{ ml: 3 }} justifyContent="space-between" alignItems="flex-end">
              <Grid item>
                <Typography variant="subtitle1" color="primary">
                  {facilityName}
                </Typography>
              </Grid>
              <Grid item>
                <CartDropdown />
              </Grid>
            </Grid>
          </>
        ) : (
          isNewRental && (
            <>
              <Grid container direction="row" sx={{ ml: 3 }} justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Grid container direction="row" spacing={1} alignItems="center">
                    <Grid item>
                      <RentalCategoryDropdown />
                    </Grid>
                    <Grid item>
                      <RentalSubcategorySelect />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={1} alignItems="center">
                    <Grid item>
                      <RentalSearch />
                    </Grid>
                    <Grid item>
                      <RentalCartDropdown />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )
        )}
      </Grid>
    </Grid>
  );
}
