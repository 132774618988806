import { Box, Button, Grid, Stack, TextField, Tooltip, Typography } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useParams } from 'react-router-dom';
import { saveBudgetBreakdownData } from '../../../../redux/slices/orders';
import { useDispatch, useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';

BudgetBreakdown.propTypes = {
  selectedRow: PropTypes.object,
};

export default function BudgetBreakdown({ selectedRow }) {
  const { id = '' } = useParams();
  const [localUnitPrice, setLocalUnitPrice] = useState(0);
  const [localQty, setLocalQty] = useState(0);
  const [changesMade, setChangesMade] = useState(false);
  const [localBudgetSource, setLocalBudgetSource] = useState(0);
  const [localBudgetSourceData, setLocalBudgetSourceData] = useState([]);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setLocalData();
  }, []);

  const setLocalData = () => {
    setLocalUnitPrice(selectedRow.budgetPrice);
    setLocalQty(selectedRow.budgetQty);
    setLocalBudgetSource(selectedRow.budgetSource);
    setLocalBudgetSourceData(selectedRow.budgetSourceData);
    setChangesMade(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLocalData();
  };

  const saveBudget = () => {
    if (Number(localUnitPrice) !== selectedRow.budgetPrice[0] || Number(localQty) !== selectedRow.budgetQty[0]) {
      dispatch(saveBudgetBreakdownData(id, selectedRow.id, localUnitPrice, localQty));
      setLocalBudgetSource('MANUAL');
      setLocalBudgetSourceData([`${user.firstName} ${user.lastName}`]);
    }
    handleClose();
  };

  const getTotalDataForArray = () => {
    const totalSum = selectedRow.budgetSourceData?.reduce((accumulator, data, index) => {
      const itemTotal = selectedRow.budgetPrice[index] * selectedRow.budgetQty[index];
      return accumulator + itemTotal;
    }, 0);
    return totalSum;
  };

  const budgetMenu = (
    <Box>
      <Grid container color="common.black" direction="column" textAlign="center">
        <Grid item sx={{ mt: 1 }}>
          <Typography color="secondary" fontWeight="bold">
            Budget Breakdown
          </Typography>
        </Grid>
        {selectedRow.budgetSourceData?.map((row, index) => (
          <span key={index}>
            <Grid item>
              <Typography fontSize={12}>
                {localBudgetSource === 'MANUAL'
                  ? `Data adjusted by ${localBudgetSourceData}`
                  : `Data pulling from ${localBudgetSource}#${selectedRow.budgetSourceData?.length > 1
                    ? selectedRow.budgetSourceData[index]
                    : localBudgetSourceData
                  }`}
              </Typography>
              <Stack
                spacing={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  mt: 1,
                }}
              >
                <Typography>Unit Price:</Typography>
                <NumericFormat
                  value={Number(selectedRow.budgetSourceData?.length > 1 ? selectedRow.budgetPrice[index] : localUnitPrice)}
                  customInput={TextField}
                  sx={{ width: '43%', pr: 3 }}
                  color="primary"
                  variant="standard"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  onValueChange={(values) => {
                    const { value } = values;
                    setLocalUnitPrice(value);
                    setChangesMade(true);
                  }}
                />
              </Stack>
            </Grid>
            <Grid item>
              <Stack spacing={1} direction="row" justifyContent="center" alignItems="center">
                <Typography>Quantity:</Typography>
                <TextField
                  sx={{ width: '30%' }}
                  value={selectedRow.budgetSourceData?.length > 1 ? selectedRow.budgetQty[index] : localQty}
                  color="primary"
                  variant="standard"
                  type="number"
                  onChange={(e) => {
                    setLocalQty(e.target.value);
                    setChangesMade(true);
                  }}
                />
              </Stack>
            </Grid>
          </span>
        ))}
        {changesMade ? (
          <Grid item>
            <Button
              disabled={selectedRow.budgetSourceData?.length > 1}
              variant="contained"
              color="primary"
              size="small"
              sx={{
                minWidth: 120,
                marginTop: 2,
              }}
              onClick={() => {
                saveBudget();
              }}
            >
              Save
            </Button>
          </Grid>) :
          <Grid sx={{ p: 1 }} />
        }
      </Grid>
    </Box>
  );

  return (
    <>
      <span>
        ${(selectedRow.budgetSourceData?.length > 1 ? getTotalDataForArray() : localQty * localUnitPrice).toFixed(2)}
      </span>
      <ClickAwayListener onClickAway={handleClose}>
        <span>
          <Tooltip
            placement="bottom-end"
            title={budgetMenu}
            arrow
            onClose={handleClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: '#fff',
                  borderRadius: "6px",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px 0px",
                  backgroundColor: "rgb(255, 255, 255)",
                  border: "1px solid rgb(193, 201, 208)",
                  '& .MuiTooltip-arrow': {
                    color: '#fff',
                    "&:before": {
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px 0px",
                      backgroundColor: "rgb(255, 255, 255)",
                      border: "1px solid rgb(193, 201, 208)",
                    }
                  }
                }
              }
            }}
          >
            <Tooltip title={'Budget Breakdown'}>
              <Iconify
                color="secondary"
                onClick={handleOpen}
                sx={{ opacity: 0.8 }}
                icon="basil:settings-adjust-outline"
              />
            </Tooltip>
          </Tooltip>
        </span>
      </ClickAwayListener>
    </>
  );
}
