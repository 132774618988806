import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useEffect, useState } from 'react';
import { getWarehouseVendorData } from '../../../../api/warehouse-vendors';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import WarehouseVendorLinks from './WarehouseVendorLinks';
import WarehouseVendors from './WarehouseVendors';
import WarehouseVendorIntegrations from './WarehouseVendorIntegrations';

export default function WarehouseVendorsView() {
  const { enqueueSnackbar } = useSnackbar();
  const [tab, setTab] = useState(0);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const fetchVendorData = async () => {
    setIsLoading(true);
    const response = await getWarehouseVendorData();
    if (response.status === 200) {
      setData(response.data);
    } else {
      enqueueSnackbar('Error fetching warehouse vendors', { variant: 'error' });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchVendorData();
  }, [])

  const renderTabContent = () => {
    switch (tab) {
      case 0:
        return <WarehouseVendors vendorData={data} fetchVendorData={fetchVendorData} isLoading={isLoading} />;
      case 1:
        return <WarehouseVendorLinks vendorList={data} fetchVendorData={fetchVendorData} />;
      default:
        return <WarehouseVendorIntegrations vendorList={data} />;
    }
  };

  return (
    <Box>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Box>
          <Tabs value={tab}
            onChange={handleTabChange}
            indicatorColor="transparent"
            sx={{ borderRadius: '4px 4px 0px 0px' }}>
            <CustomTab label={<Typography>Vendors</Typography>} />
            <CustomTab label={<Typography>Vendor Links</Typography>} />
            <CustomTab label={<Typography>Vendor Integrations</Typography>} />
          </Tabs>
        </Box>
      </Grid>
      {renderTabContent()}
    </Box>
  );
}

const CustomTab = styled((props) => <Tab {...props} />)(({ selected }) => ({
  flex: 1,
  minWidth: '250px',
  minHeight: '43px',
  height: '43px',
  maxHeight: '43px',
  borderBottom: 'none',
  marginTop: '5px',
  marginLeft: '4px',
  marginRight: '4px',
  borderLeft: 'none',
  borderRight: 'none',
  borderTop: 'none',
  color: '#444',
  borderRadius: '5px 5px 0px 0px',
  backgroundColor: selected ? '#fff' : '#f4f6f8',
  boxShadow: selected ? '0px 2px 4px 1px rgba(0, 0, 0, 0.2)' : '0px 1px 2px rgba(0, 0, 0, 0.1)',
  transition: 'background-color 0.2s ease-in-out, color 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: '#fff',
    color: '#000',
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.2)',
  },
  '& .MuiTab-wrapper': {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
