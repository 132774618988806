import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Autocomplete,
  Typography,
  Button,
  TextField,
  Tabs,
  Tab,
  DialogActions,
  CircularProgress,
  Tooltip,
  createFilterOptions,
  Box
} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { TabPanel } from '../../../../reusable-components/tab-panel';
import { getOrderGuidesByFacilityAndCategory, saveOrderGuideVendorPreferences,  } from '../../../../redux/slices/orderGuides';
import { AwaitButton } from '../../../../reusable-components/await-button';
import { getOrderGuideVendorPreferencesData } from '../../../../api/order-guides';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import Iconify from '../../../../reusable-components/iconify/Iconify';
import { useDispatch } from '../../../../redux/store';

OrderGuideVendorPreferences.propTypes = {
  orderGuideData: PropTypes.object,
  orderGuideId: PropTypes.number
};

export default function OrderGuideVendorPreferences({ orderGuideData, orderGuideId }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [vendorPreferences, setVendorPreferences] = useState(null);
  const [subcategories, setSubcategories] = useState(null);
  const [vendors, setVendors] = useState(null);
  const [allOptions, setAllOptions] = useState([]);
  const [selectedToDelete, setSelectedToDelete] = useState(null);
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(0);

  const cols = [
    {
      field: 'agoraSubcategory',
      headerName: 'Subcategory',
      minWidth: 80,
      flex: 1,
      type: 'customText',
    },
    {
      field: 'vendor',
      headerName: 'Vendor',
      minWidth: 80,
      flex: 1,
      type: 'customText',
    }   
  ]

  useEffect(() => {
    if (!open) return;
    setSelectedToDelete([]);
    if (orderGuideId === 0) setVendorPreferences(null);
    else if (orderGuideData) {
      setVendorPreferences(orderGuideData.preferences.map((preference, index) => ({ ...preference, id: index })));
      setSubcategories(orderGuideData.subcategories);
      setVendors(orderGuideData.vendors);
    }
    else {
      getOrderGuideVendorPreferencesData(orderGuideId)
        .then(res => {
          setVendorPreferences(res.data.preferences.map((preference, index) => ({ ...preference, id: index })));
          setSubcategories(res.data.subcategories);
          setVendors(res.data.vendors);
        })
        .catch(() => enqueueSnackbar("Failed to load order guide vendor preferences", { variant: "error" }))
    }
  }, [orderGuideData, orderGuideId, open]);

  useEffect(() => {
    setAllOptions(subcategories?.flatMap(subcategory =>
      vendors.map(vendor => ({
        value: vendorPreferences.some(preference => preference.isRestricted === (tab === 1) &&
          preference.vendorId === vendor.value && preference.agoraSubcategoryId === subcategory.id) ? 1 : 0,
        label: `${subcategory.name} - ${vendor.label}`,
        agoraSubcategoryId: subcategory.id,
        agoraSubcategory: subcategory.name,
        vendor: vendor.label,
        vendorId: vendor.value
      }))
    ));
  }, [subcategories, vendors, vendorPreferences, tab]);

  const defaultAutocompleteSettings = {
    size: "small",
    fullWidth: true,
    isOptionEqualToValue: (option, value) => option?.value === value?.value,
    renderInput: (params) => <TextField {...params} label="Search or select" />,
    sx: {
      '& legend': { display: 'none' },
      '& fieldset': { top: 0 },
      '& .MuiFormLabel-root ': { display: 'none' },
      '& span.MuiAutocomplete-tagSizeSmall ': { color: 'primary.main' },
    }
  };

  const PreferencesSelects = (isRestricted) => <>
    <Typography variant="subtitle1" sx={{ mt: 1, textAlign: "center", fontSize: "16px" }}>
      {isRestricted ? "Restricted Vendors" : "Preferred Vendors"}
    </Typography>
    <Box>Autofill Vendor</Box>
    <Autocomplete
      {...defaultAutocompleteSettings}
      limitTags={10}
      options={vendors} 
      multiple
      value={(vendorPreferences ?? []).filter((preference) => preference.isRestricted === isRestricted).reduce((acc, preference) => {
        acc[preference.vendorId] = { value: preference.vendorId, label: preference.vendor }
        return acc;
      }, []).filter(preference => preference !== null)}
      onChange={(e, newValue) => {
        if (newValue.length === 0) {
          setVendorPreferences(preferences => preferences.filter(preference => preference.isRestricted !== isRestricted));
          return;
        }
        setVendorPreferences(preferences => {
          const oldVendors = preferences.filter(preference => preference.isRestricted === isRestricted).map(val => val.vendorId);
          const newValues = newValue.map(val => val.value);
          const newVendors = newValues.filter(val => !oldVendors.includes(val));
          const filteredPreferences = preferences.filter(preference => preference.isRestricted === isRestricted &&
            newValues.includes(preference.vendorId));
          if (newVendors.length === 0) return [...preferences.filter(preference => preference.isRestricted !== isRestricted), ...filteredPreferences];
          const vendorName = vendors.find(vendor => vendor.value === newVendors[0])?.label;
          const oldSubcats = filteredPreferences.filter(preference => preference.agoraSubcategoryId &&
            (!isRestricted || preference.vendorId === newVendors[0])).map(preference => preference.agoraSubcategoryId);
          if (oldSubcats.length === subcategories.length) enqueueSnackbar("All possible categories already filled", { variant: "error" });
          return [...preferences.filter(preference => preference.isRestricted !== isRestricted),
          ...filteredPreferences,
          ...subcategories.filter(subcategory => !oldSubcats.includes(subcategory.id))
            .map(subcategory => ({
              agoraSubcategoryId: subcategory.id,
              agoraSubcategory: subcategory.name,
              vendor: vendorName,
              vendorId: newVendors[0],
              isRestricted
            }))].map((preference, index) => ({ ...preference, id: index }));
        });
      }}     
    />
    <Box variant="subtitle1" sx={{ mt: 1 }}>Add Category - Vendor</Box>
    <Autocomplete
      {...defaultAutocompleteSettings}
      filterOptions={createFilterOptions({ limit: 500 })}
      blurOnSelect
      options={allOptions ?? []}
      value={{ value: 1, label: "Search or select" }}
      onChange={(e, newValue) => {
        if (!newValue) return;
        setVendorPreferences(preferences => {
          const existingPreference = preferences.find(preference => preference.isRestricted === isRestricted &&
            preference.agoraSubcategoryId === newValue.agoraSubcategoryId && (!isRestricted || preference.vendorId === newValue.vendorId));
          if (existingPreference && existingPreference.vendorId === newValue.vendorId)
            return preferences.filter(preference => preference !== existingPreference);
          const filteredPreferences = existingPreference ? preferences.filter(preference => preference !== existingPreference) : preferences;
          return [...filteredPreferences, {
            ...newValue,
            active: true,
            isRestricted
          }].map((preference, index) => ({ ...preference, id: index }));
        })
      }}    
    />
  </>;

  const defaultGirdSettings = {
    boxSX: { height: 300 },
    scrollbarHeight: 56,
    gridColumns: cols,
    isLoading: vendorPreferences === null,
    onRowSelectionModelChange: (newSelection) => {
      setSelectedToDelete(newSelection);
    },
    rowSelectionModel: selectedToDelete,
    checkboxSelection: true,
    displayCheckboxRight: true,
    CustomLeftToolbar: () => <>
      <Box>{(tab === 0 ? "Preferred" : "Restricted")} Vendor List</Box>
      {selectedToDelete?.length > 0 && <Button
        size="small"
        color="error"
        variant="text"
        startIcon={<Iconify icon="ph:check-fill" />}
        onClick={() => { setVendorPreferences(preferences => [...preferences.filter(preference => !selectedToDelete.includes(preference.id))]); }}
      >
        Delete selected items
      </Button>}
    </>
  }

  const savePreferences = async () => {
    dispatch(saveOrderGuideVendorPreferences({
      id: orderGuideId,
      tempPreferences: vendorPreferences?.filter(preference => !preference.isRestricted),
      tempRestricted: vendorPreferences?.filter(preference => preference.isRestricted),
      closeAndReloadOrderGuide: () => {
        enqueueSnackbar("Vendor preferences saved", { variant: "success" })
        if (orderGuideData) dispatch(getOrderGuidesByFacilityAndCategory(orderGuideId));
        setOpen(false);
      }
    }));
  }

  return (
    <>
      <Tooltip title="Vendor Preferences" arrow>
        <IconButton color="secondary" id="action-button" className="action-button" onClick={() => setOpen(true)}>
          <TuneIcon id="action-button" className="action-button" />
        </IconButton>
      </Tooltip>

      <Dialog open={open} maxWidth="md" fullWidth>
        <DialogTitle>
          <Tabs value={tab} onChange={(event, newValue) => setTab(newValue)}>
            <Tab label="Preferred Vendor" />
            <Tab label="Restricted Vendors" />
          </Tabs>
        </DialogTitle>
        {vendorPreferences ? <DialogContent sx={{ width: '100%' }}>         
          <TabPanel value={tab} index={0}>
            {PreferencesSelects(false)}
            <Box sx={{ mt: 3 }}>
              <CustomDataGrid
                gridId="order-guide-vendor-preferences"
                data={vendorPreferences?.filter(preference => !preference.isRestricted)}
                {...defaultGirdSettings}       
                />
            </Box>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            {PreferencesSelects(true)}
            <Box sx={{ mt: 3 }}>
              <CustomDataGrid
                gridId="order-guide-vendor-restricted"
                data={vendorPreferences?.filter(preference => preference.isRestricted) ?? []}
                {...defaultGirdSettings}  
                />
            </Box>
          </TabPanel>
        </DialogContent> : <CircularProgress sx={{ position: 'absolute', left: '50%', top: '50%' }} />}
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <AwaitButton variant="contained" color="primary" size="medium" sx={{ width: 150 }} onClick={savePreferences}>
            Save
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
