import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Button, Grid, Menu, MenuItem, Typography } from '@mui/material';
import { CartView, GrandTotal } from '../../../../components/dashboard/orders/new-order/cart';
import { useDispatch, useSelector } from '../../../../redux/store';
import { bulkUpdateCart, cartCheck, getCart } from '../../../../redux/slices/cart';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import { AwaitButton } from '../../../../reusable-components/await-button';
import Iconify from '../../../../reusable-components/iconify/Iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import { deleteCartApi } from '../../../../api/cart';

export default function Cart() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { facilityId = '' } = useParams();
  const { data: { carts, facility, unsavedChanges }, isLoading } = useSelector((state) => state.cart);

  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    dispatch(getCart(facilityId));
  }, [dispatch, facilityId]);
  const [showSelection, setShowSelection] = useState('');

  const cartCheckFunction = () => {
    setShowErrorMsg(true);
    const res = dispatch(cartCheck(facilityId));
    res.then((response) => {
      if (response) enqueueSnackbar('Cart Check Successful', { variant: 'success' });
      else enqueueSnackbar('Cart Check Failed', { variant: 'error' });
    });
  };

  const closeMenu = (e) => {
    setShowSelection(e?.target?.textContent || '');
    setAnchorEl(null);
  };

  const bulkUpdate = () => {
    dispatch(bulkUpdateCart(facilityId));
  };

  const deleteCart = async () => {
    setAnchorEl(null);
    await deleteCartApi(facilityId);
    navigate(`/dashboard/home`);
  };

  return (
    <>
      <Helmet>
        <title>Cart | Platform Solutions</title>
      </Helmet>
      {carts?.some(cart => cart.items?.length > 0 ) && <>      
        <Typography variant="subtitle1" color="primary">
          {facility?.label}
        </Typography>
        <ScopeGuard scopes={['View-Order']} allowAdmin>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <ScopeGuard scopes={['Cart-Check', 'Edit-Order']} allowAdmin>
                <AwaitButton
                  variant="outlined"
                  size="small"
                  color="info"
                  startIcon={<Iconify icon="mdi:cart-check" />}
                  sx={{ mr: 2 }}
                  onClick={cartCheckFunction}
                  loading={isLoading}
                >
                  Cart Check
                </AwaitButton>
              </ScopeGuard>
              <Button
                variant="outlined"
                sx={{ mr: 1 }}
                size="small"
                onClick={() => {
                  window.open(`Api/DownloadCart?facilityId=${facilityId}`, '_blank');
                }}
                startIcon={<Iconify icon="mdi:microsoft-excel" />}
                color="secondary"
              >
                Cart Excel
              </Button>
              <Button
                variant="outlined"
                sx={{ mr: 1 }}
                size="small"
                onClick={() => {
                  window.open(`Api/DownloadCartPDF?facilityId=${facilityId}`, '_blank');
                }}
                startIcon={<Iconify icon="tabler:file-type-pdf" />}
                color="secondary"
              >
                Cart PDF
              </Button>
              <AwaitButton
                variant="outlined"
                size="small"
                startIcon={<Iconify icon="tabler:refresh" />}
                onClick={bulkUpdate}
                sx={{ mr: 2 }}
                disabled={!unsavedChanges}
              >
                Save Changes to Cart
              </AwaitButton>
              <Button
                size="small"
                color="error"
                onClick={e => setAnchorEl(e.currentTarget)}
                variant="outlined"
                startIcon={<Iconify icon="mdi:dots-horizontal" width={24} height={24} sx={{ ml: 1 }} />}
              />

              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                <MenuItem onClick={closeMenu}>
                  <Button size="small" color="error" variant="text" startIcon={<Iconify icon="ph:check-fill" />}>
                    Select items to delete
                  </Button>
                </MenuItem>
                <MenuItem>
                  <ConfirmDialog
                    icon="delete-outline"
                    title="Delete all carts?"
                    color="error"
                    content="Doing so will delete all items in all carts. Are you sure you want to delete all carts?"
                    actionButton="Delete all carts"
                    button="text"
                    sx={{ mr: 2 }}
                    fullWidth
                    action={deleteCart}
                  />
                </MenuItem>
              </Menu>
            </Grid>
            <Grid item xs={2.5}>
              <GrandTotal />
            </Grid>
          </Grid>{' '}
        </ScopeGuard>         
      </>}      
      {carts.map((items, index) => (
        <CartView
          key={index}
          items={items}
          index={index}
          setShowSelection={setShowSelection}
          showSelection={showSelection}
          showErrorMsg={showErrorMsg}
        />
      ))}
    </>
  );
}
